'use strict';

import $ from 'jquery';

/*
  This directive will handle all the post processing required
  by badgame's BBC generation. bgpost is only related to post rendering.

  Links are also given a custom click handler so they stay in app
*/
angular.module('bgAngularApp')
  .directive('bgpost', ['$timeout', '$location', '$sce', '$rootScope', 'badgame', function($timeout, $location, $sce, $rootScope, badgame) {
    // Query parameter extraction
    function getParam(url, name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(url);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    function checkLinks(scope, element) {
      var href = $(element).attr('href');

      /*
        Attempt to extract to topic/board from badgame
        links so we route them correctly in the app.
      */
      if(href && href.indexOf('//badgame.net') > -1) {
        var topic = getParam(href, 'topic');
        var board = getParam(href, 'board');

        // Route to topic
        if(topic) {
          $(element).bind('click', function(event) {
            event.preventDefault();
            var topicParams = topic.split('.');
            $location.hash(topicParams[1]);
            $location.path('/topic/' + topicParams[0] + '/' + topicParams[1]);
            scope.$apply();
          });
        // Route to board
        } else if(board) {
          $(element).bind('click', function(event) {
            event.preventDefault();
            var boardParams = board.split('.');
            $location.path('/board/' + boardParams[0]);
            scope.$apply();
          });
        }
      }
    }

    return {
      restict: 'A',
      link: function(scope, element, attr) {
        $timeout(function() {
          // Avatar/Name tag binding
          $(element).find(".showAvatar").each(function() {
            var avImg = $("<img>");
            avImg.attr("src", badgame.avatar);
            avImg.appendTo($(this));
          });

          $(element).find(".showUsername").each(function() {
            $(this).text(badgame.username);
          });

          // Hidden block handler
          $(element).find('.hiddenheader').click(function() {
              var hiddenblock = $(this).next('.hiddenblock');
              if ($(this).hasClass('nwsheader')) {
                  var html = hiddenblock.find('textarea').val();
                  hiddenblock.find('textarea').replaceWith(html);
                  if(!localStorage.getItem('hideImages')) {
                    $(element).find('img:hidden').show();
                  }
              }
              hiddenblock.slideToggle();
          });

          // Spoil click toggle for mobile devices
          $(element).find('.spoiler').click(function() {
            $(this).toggleClass('spoiler');
          });

          // gfy
          $(element).find('.gfyitem').each(function(key, val) {
            var injection = '<iframe src="SRC_SWAP" frameborder="0" scrolling="no" allowfullscreen></iframe>';

            $('.gfyitem').each(function(idx, gfytag) {
              var data_id = $(gfytag).attr('data-id');
              $(gfytag).removeClass('gfyitem');

              var inject_code = injection.replace('SRC_SWAP', 'https://gfycat.com/ifr/' + data_id);

              var el = $(inject_code);
              $(gfytag).append(el);
            });
          });

          // Tweet say
          $(element).find('.tweet-say').each( function(idx, el) {
              var msg = $(el).text();
              var timestamp = $(el).attr('timestamp');

              var template = $('#tweet-template').html();
              $(el).html(template);
              $(el).find('.tweet-body').text(msg);
              $(el).find('.tweet-timestamp').text(timestamp);
          });


          // Vimeo
          $(element).find('.vimeo').each(function(key, val) {
            var vimeoLink = 'https://vimeo.com/' + $(val).text();
            $(val).html('<a href="' + vimeoLink + '">' + vimeoLink + '</a>');
          });

          // webm
          $(element).find('.webm-video').each(function () {
            if ($(this).parents('.quote').length > 0) return;
            var url = $(this).find('a').attr('href');
            $(this).replaceWith(
              '<video class="img-responsive" style="max-height: 480px" loop muted controls preload="none">' +
              '<source src="' + url + '" type="video/webm;" codecs="vp8, vorbis">' +
              '</video>');
          });

          // Youtube heights
          $(element).find('.youtube').each(function() {
            var v_el = $(this);
            $(v_el).attr('style', '');

            if ($rootScope.useYewtube) {
              var player_el = $(v_el).find('.youtube-player').first();

              var origSrc = $(player_el).attr("src");
              var trail = origSrc.substring(origSrc.lastIndexOf("/") + 1);
              trail = trail.replaceAll("autoplay", ""); // Sanity check against inject

              var arg_sep = "?";
              if (trail.indexOf(arg_sep) > 0) {
                arg_sep = "&";
              }

              $(player_el).attr("src", "https://yewtu.be/embed/" + trail + arg_sep + "autoplay=0");
            }

            if ($rootScope.doubleTapVideos) {
              $(v_el).find('iframe').first().attr('style', 'pointer-events: none')

              // Restore pointer events on click for the double tap
              $(v_el).on('click', () => {
                $(v_el).find('iframe').first().attr('style', '')
              });
            }
          });

          // Youtube quotes
          $(element).find(".quote .youtube").each(function() {
            var url = $(this).find(".youtube-url").html();
            $(this).replaceWith("<a href=\"" + url + "\">" + url + "</a>");
          });

          // Iframe cleanup
          $(element).find('iframe').each(function() {
            $(this).removeAttr('width');
            $(this).removeAttr('height');
          });

          // Link wrapper for links inside posts
          $(element).find('a').each(function(key, val) {
            checkLinks(scope, val);
          });

          // Observe href attr for breadcrumb links and update them directly
          attr.$observe('href', function(value) {
            checkLinks(scope, element);
          });
        });
      }
    };
  }]);

/*
  Utility directive for editing posts
*/
angular.module('bgAngularApp')
  .directive('bgpostEdit', [function() {
    return {
      restrict: 'A',
      link: function(scope, element, attr) {
        // Add a BBC tag to the message box
        scope.appendTag = function(tag) {
          var openTag = '[' + tag + ']';
          var closeTag = '[/' + tag + ']';

          var msgBox = $('#post-input');

          var oldMsg = msgBox.val();

          if (msgBox[0].selectionStart != msgBox[0].selectionEnd)
            {
              var newMsg = oldMsg.slice(0, msgBox[0].selectionStart) + openTag + oldMsg.slice(msgBox[0].selectionStart,
                  msgBox[0].selectionEnd) + closeTag + oldMsg.slice(msgBox[0].selectionEnd, oldMsg.length);
              msgBox.val(newMsg);
              msgBox.focus();

              return;
            }

          msgBox.val(msgBox.val() + openTag + closeTag);

          var position = msgBox.val().length - closeTag.length;

          msgBox[0].focus();

          msgBox[0].selectionStart = msgBox[0].selectionEnd = position;
        };

        scope.appendSmiley = function(code) {
          var msgBox = $('#post-input');
          var txt = msgBox.val();
          var pos = msgBox[0].selectionStart;

          msgBox.val(txt.substring(0, pos) + code + txt.substring(pos, txt.length));

          msgBox[0].focus();
          msgBox[0].selectionStart = pos + code.length;
        };
      }
    };

  }]);

/*
 * Helper filter for doing loops in views
 */
angular.module('bgAngularApp')
  .filter('range', [function() {
    return function(input, total) {
      total = parseInt(total);

      for (var i = 0; i < total; i++) {
        input.push(i);
      }

      return input;
    };
  }]);
