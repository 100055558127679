'use strict';

export default angular.module('bgAngularApp')
  .factory('Page', ['$rootScope', function($rootScope) {
    var badge = '';
    var title = "Badgame Lite";

    return {
      badge: function() { return badge; },
      title: function() { return badge + title; },
      reset: function() {
        badge = '';
        title = 'Badgame Lite';
      },
      setBadge: function(newBadge) {
        badge = newBadge;
      },
      setTitle: function(newTitle) {
        if(!$rootScope.disableTitles) {
          title = newTitle;
        }
      }
    }
  }]);

